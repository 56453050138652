import AdsApply from "./ads-apply.jpg"
import AdsDashboard from "./ads-dashboard.jpg"
import AdsData from "./ads-data.jpg"
import AdsDesignSystem from "./ads-design-system.jpg"
import AdsHeader from "./ads-header.png"
import AdsInline from "./ads-inline.jpg"
import AdsSketches from "./ads-sketches.jpg"
import AdsThumbnail from "./ads-thumbnail.jpg"
import DragonIndex from "./dragon-index.png"
import DragonInterviewScript from "./dragon-interview-script.pdf"
import DragonNotes from "./dragon-notes.jpg"
import DragonPath from "./dragon-path.jpg"
import DragonSolutionOne from "./dragon-solution-1.jpg"
import DragonSolutionTwo from "./dragon-solution-2.jpg"
import DragonSolutionThree from "./dragon-solution-3.jpg"
import DragonStoryboard from "./dragon-storyboard.jpg"
import DragonThumbnail from "./dragon-thumbnail.jpg"
import Favicon from "./favicon.png"
import JeffStolzResume from "./jeff_stolz_resume.pdf"
import MhDesignProcessSketch from "./mh-design-process-sketch.jpg"
import MhDiscovery from "./mh-discovery.jpg"
import MhHeader from "./mh-header.png"
import MhOverview from "./mh-overview.jpg"
import MhPersona from "./mh-persona.jpg"
import MhSolutionOne from "./mh-solution-1.jpg"
import MhSolutionTwo from "./mh-solution-2.jpg"
import MhSolutionThree from "./mh-solution-3.jpg"
import MhSolutionFour from "./mh-solution-4.jpg"
import MhSolutionFive from "./mh-solution-5.jpg"
import MhSolutionSix from "./mh-solution-6.jpg"
import MhThumbnail from "./mh-thumbnail.jpg"
import MhWireframe from "./mh-wireframe.jpg"
import NeocovaExploration from "./neocova-exploration.jpg"
import NeocovaIndex from "./neocova-index.png"
import NeocovaSolutionOne from "./neocova-solution-1.jpg"
import NeocovaSolutionTwo from "./neocova-solution-2.jpg"
import NeocovaSolutionThree from "./neocova-solution-3.jpg"
import NeocovaThumbnail from "./neocova-thumbnail.jpg"
import SkillsAthleteLanding from "./skills-athlete-landing.jpg"
import SkillsAthlete from "./skills-athlete.jpg"
import SkillsAthletes from "./skills-athletes.jpg"
import SkillsFlowDiagram from "./skills-flow-diagram.png"
import SkillsHome from "./skills-home.jpg"
import SkillsIndex from "./skills-index.png"
import SkillsMoodboardOne from "./skills-moodboard-1.jpg"
import SkillsMoodboardTwo from "./skills-moodboard-2.jpg"
import SkillsNotes from "./skills-notes.png"
import SkillsThumbnail from "./skills-thumbnail.jpg"
import SkillsVideo from "./skills-video.jpg"
import SkillsWireframes from "./skills-wireframes.jpg"
import Wave from "./wave.svg"
import IshanLogo from "./logo.svg"
import SwaasthyHero from "./swaasthy-hero.png"
import SwaasthyThumbnail from "./swaasthy-thumbnail.png"
import SwaasthyBoard1 from "./swaasthy-board-1.png"
import SwaasthyPrimaryColor from "./swaasthy-primary-colour-palette.png"
import SwaasthyAltColor from "./swaasthy-alt-colour-palette.png"
import SwaasthyArtboard from "./swaasthy-artboard.png"
import SwaasthyGoogleHome from "./swaasthy-assistant.png"
import SwaasthyDevelopment from "./swaasthy-development.png"
import SwaasthyPersona1 from "./swaasthy-persona-1.png"
import SwaasthyPersona2 from "./swaasthy-persona-2.png"

export const Images = {
  SwaasthyHero,
  SwaasthyThumbnail,
  SwaasthyBoard1,
  SwaasthyPrimaryColor,
  SwaasthyAltColor,
  SwaasthyArtboard,
  SwaasthyDevelopment,
  SwaasthyGoogleHome,
  SwaasthyPersona1,
  SwaasthyPersona2,
  AdsApply,
  AdsDashboard,
  AdsData,
  AdsDesignSystem,
  AdsHeader,
  AdsInline,
  AdsSketches,
  AdsThumbnail,
  DragonIndex,
  DragonInterviewScript,
  DragonNotes,
  DragonPath,
  DragonSolutionOne,
  DragonSolutionTwo,
  DragonSolutionThree,
  DragonStoryboard,
  DragonThumbnail,
  Favicon,
  JeffStolzResume,
  MhDesignProcessSketch,
  MhDiscovery,
  MhHeader,
  MhOverview,
  MhPersona,
  MhSolutionOne,
  MhSolutionTwo,
  MhSolutionThree,
  MhSolutionFour,
  MhSolutionFive,
  MhSolutionSix,
  MhThumbnail,
  MhWireframe,
  NeocovaThumbnail,
  NeocovaExploration,
  NeocovaIndex,
  NeocovaSolutionOne,
  NeocovaSolutionTwo,
  NeocovaSolutionThree,
  SkillsAthleteLanding,
  SkillsAthlete,
  SkillsAthletes,
  SkillsFlowDiagram,
  SkillsHome,
  SkillsIndex,
  SkillsMoodboardOne,
  SkillsMoodboardTwo,
  SkillsNotes,
  SkillsThumbnail,
  SkillsVideo,
  SkillsWireframes,
  Wave,
  IshanLogo,
}
